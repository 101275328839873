$bodyFontList: (-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif)
$codeFontList: (source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace)

.body
  margin: 0
  font-family: $bodyFontList
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  background-color: #282c34

  code
    font-family: $codeFontList

#root *
  -ms-overflow-style: none
  scrollbar-width: none

#root *::-webkit-scrollbar
  display: none
