body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  height: 100%;
  box-sizing: border-box;
}

/* Important for border overflowing */
*,
*:before,
*:after {
  box-sizing: inherit;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
}

body {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

body::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.special-link,
.special-link:visited,
.special-link:active {
  color: #007bff;
  text-decoration: none;
}

.special-link:hover {
  color: #007bff;
  text-decoration: underline;
}